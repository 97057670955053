<template>
  <div>
    <h1>シフト表</h1>

    <v-card outlined class="mt-5 px-5">
      <v-card-title primary-title>
        日付選択
      </v-card-title>
      <v-divider></v-divider>

      <v-radio-group
        v-model="viewType"
        class="ml-5"
        row
        label="表示種別："
        prepend-icon="mdi-toggle-switch"
      >
        <v-radio
          v-for="type in ViewTypes"
          :key="type.value"
          :label="type.text"
          :value="type.value"
        />
      </v-radio-group>

      <DatePicker
        v-model="date"
        :week.sync="week"
        :format.sync="format"
        label="日選択"
        prepend-inner-icon="mdi-calendar"
        outlined
        next
        previous
        :type="viewType"
        class="pulldown-z-index"
      />
      <v-autocomplete
        v-if="dataType === DataTypes[0].value"
        v-model="selected"
        label="所属選択"
        :items="offices"
        outlined
        multiple
        clearable
        chips
        item-text="name"
        item-value="code"
        class="pulldown-z-index"
      ></v-autocomplete>
      <v-card-actions>
        <v-spacer></v-spacer>
        <Btn
          icon="send"
          color="success"
          :disabled="$isEmpty(selected.length)"
          @click="click('query')"
          >取得</Btn
        >
      </v-card-actions>
    </v-card>
    <v-card
      v-if="dataType == DataTypes[0].value && !!calendar && calendar.length"
      class="office mt-5"
    >
      <v-toolbar color="primary">
        <span class="headline white--text"> {{ this.tableTitle }} </span>
        <div></div>
        <!-- <v-btn v-show="delmode" class="" color="error" @click="deleteded()"
          >実績にないシフトを削除</v-btn
        > -->
        <v-btn class="mx-2" dark text outlined @click="click('deployment')"
          >現状届から展開</v-btn
        >
        <v-spacer></v-spacer>
        <!-- <v-btn class="mx-2" dark text outlined @click="click('copy')"
          >前{{
            ViewTypes.find(x => x.value === viewType).text
          }}からコピー</v-btn
        > -->
        <v-btn class="" dark text outlined @click="excel()">Excel出力</v-btn>
        <v-btn class="mx-2" dark text outlined @click="save()">更新</v-btn>
      </v-toolbar>

      <template v-for="affiliation in affiliations">
        <table class="mt-4 mx-4" border="1" :key="affiliation.id">
          <caption class="text-h6">
            {{
              affiliation.name
            }}
          </caption>
          <thead>
            <tr>
              <th rowspan="2" class="fixed">氏名</th>
              <th
                v-for="(cal, i) in calendar"
                :key="i"
                :class="
                  cal.hvacation === '1'
                    ? 'orange--text'
                    : cal.dayofweek === '日'
                    ? 'red--text'
                    : cal.dayofweek === '土'
                    ? 'blue--text'
                    : ''
                "
              >
                {{ cal.day }}
              </th>
            </tr>
            <tr>
              <th v-for="(cal, i) in calendar" :key="i">
                <span
                  :class="
                    cal.hvacation === '1'
                      ? 'orange--text'
                      : cal.dayofweek === '日'
                      ? 'red--text'
                      : cal.dayofweek === '土'
                      ? 'blue--text'
                      : ''
                  "
                  >{{ cal.dayofweek }}</span
                >
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(shiftvalue, i) in shiftvalues[affiliation.index]"
              :key="i"
            >
              <th class="name fixed" @click="moveAttendanceDetails(shiftvalue)">
                <span>{{ shiftvalue.username }}</span>
              </th>
              <td
                class="days text-center"
                v-for="(day, j) in shiftvalue.shiftdays"
                :key="j"
                :class="
                  day.warning === '1'
                    ? 'days text-center warning-col'
                    : 'days text-center'
                "
              >
                <PatternMenu
                  color="primary"
                  text-value="abbreviate"
                  tooltip-value="name"
                  v-model="day.inputpatternindex"
                  :items="shiftvalue.patterns"
                  avatar-color="indigo"
                  text-color="white"
                  :default-text="day.pattern"
                  :objdata="day"
                  :key="getKey(day)"
                  :disabled="day.disabled === '1' ? true : false"
                />
              </td>
            </tr>
            <tr>
              <th class="fixed">イベント</th>
              <td
                v-for="(v, j) in shiftEvents[affiliation.index]"
                :key="j"
                class="text-center shift-event"
              >
                {{ getEventValues(v, affiliation.index) }}
              </td>
            </tr>
            <tr>
              <th class="fixed">人員設定</th>
              <td
                v-for="(v, j) in usercounts[affiliation.index]"
                :key="j"
                class="text-center"
              >
                {{ getUserCountValue(v) }}
              </td>
            </tr>
            <tr>
              <th rowspan="2" class="fixed"></th>

              <td
                v-for="(cal, i) in calendar"
                :key="i"
                class="text-center bold-text"
              >
                <span
                  :class="
                    cal.hvacation === '1'
                      ? 'orange--text'
                      : cal.dayofweek === '日'
                      ? 'red--text'
                      : cal.dayofweek === '土'
                      ? 'blue--text'
                      : ''
                  "
                  >{{ cal.dayofweek }}</span
                >
              </td>
            </tr>
            <tr>
              <td
                v-for="(cal, i) in calendar"
                :key="i"
                :class="
                  cal.hvacation === '1'
                    ? 'orange--text text-center bold-text'
                    : cal.dayofweek === '日'
                    ? 'red--text text-center bold-text'
                    : cal.dayofweek === '土'
                    ? 'blue--text text-center bold-text'
                    : 'text-center bold-text'
                "
              >
                {{ cal.day }}
              </td>
            </tr>
            <tr>
              <td :colspan="calendar.length + 3">
                <Btn
                  icon="plus"
                  color="success"
                  value="社員追加"
                  text
                  @click="addUser(affiliation)"
                />
                <Btn
                  color="secondary"
                  icon="mdi-tune"
                  value="シフト一括設定"
                  text
                  @click="clickShiftSupport(affiliation)"
                />
                <Btn
                  color="info"
                  icon="mdi-chart-timeline"
                  value="シフトパターン編集"
                  text
                  @click="clickShift"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </v-card>
  </div>
</template>

<script>
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShiftUserAddDialogVue from "./dialogs/ShiftUserAddDialog.vue";
import ShiftSupportDialogVue from "./dialogs/ShiftSupportDialog.vue";
import PatternMenu from "@/components/PatternMenu";
const now = new Date();
const DataTypes = [{ text: "事務所", value: "office" }];
const ViewTypes = [{ text: "月", value: "month" }];

export default {
  name: "Shift",
  mixins: [Common, ShowDialogs, Forms, Api],
  components: { PatternMenu },
  data: () => ({
    visible: false,
    date: now.toISOString().substr(0, 10),
    week: 1,
    format: "",
    tableTitle: "",
    calendar: [],
    events: [],
    user: null,
    users: [],
    patterns: [],
    dataType: DataTypes[0].value,
    viewType: ViewTypes[0].value,
    DataTypes,
    ViewTypes,
    offices: [],
    selected: [],
    affiliations: [],
    shiftvalues: [],
    watchShiftValues: false,
    usercounts: [],
    shiftEvents: [],
    upsertParam: [],
    delmode: false
  }),
  computed: {
    Date() {
      return new Date(this.date);
    }
  },
  watch: {
    shiftvalues: {
      handler: function(newVal, oldVal) {
        console.log("watch shiftvalues", newVal, oldVal);
        if (this.watchShiftValues) {
          console.log("Go to calcUserCount");
          this.calcUserCount();
        }
      },
      deep: true
    }
  },
  methods: {
    async moveAttendanceDetails(shiftvalue) {
      console.log("moveAttendanceDetails", shiftvalue);
      const result = await this.$confirm(
        "勤務実績に移動しますか？\n(未保存のデータは破棄されます。)",
        "確認"
      );
      if (!result) return;
      const param = {
        userincode: shiftvalue.shiftdays[0].userincode,
        targetdate: shiftvalue.shiftdays[15].datestr,
        type: "1",
        filter: {
          selected: this.selected,
          date: this.date
        }
      };
      this.$router.push({ name: "Attendance", params: param });
    },
    getEventValues(obj) {
      return obj.eventname;
    },
    getUserCountValue(obj) {
      return obj[1];
    },
    async clickShiftSupport(affiliation) {
      console.log("clickShiftSupport", affiliation);

      const target = this.shiftvalues[affiliation.index];
      if (target.length == 0) {
        this.$warning("社員が設定されていません。");
        return;
      }
      console.log(target);

      const patternList = [];
      target[0].patterns.forEach(pattern => {
        patternList.push({
          code: pattern.index,
          name: pattern.name,
          abbreviate: pattern.abbreviate,
          starttime: pattern.startTime,
          endTime: pattern.endTime
        });
      });

      const dateList = [];
      target[0].shiftdays.forEach(day => {
        dateList.push({
          code: day.datestr,
          name: day.datestr + "(" + day.dayofweek + ")"
        });
      });

      const userList = [];
      target.forEach(t => {
        userList.push({
          code: t.usercode,
          name: t.username
        });
      });

      const param = {
        targetIndex: affiliation.index,
        users: userList,
        pattern: patternList,
        days: dateList,
        affiliationname: affiliation.name
      };

      console.log("clickShiftSupport param", param);
      const result = await this.$showDialog(ShiftSupportDialogVue, {
        args: param
      });

      console.log("clickShiftSupport result", result);

      if (result.length == 0) return;

      result.forEach(r => {
        const userindex = target.findIndex(function(e) {
          return e.usercode === r.user;
        });
        console.log("index", userindex);

        console.log("target", r.user, r.date, userindex);
        const dayindex = target[userindex].shiftdays.findIndex(function(e) {
          return e.datestr === r.date;
        });
        console.log("index2", dayindex);

        const dayprop = target[userindex].shiftdays[dayindex];
        dayprop.inputpatternindex = r.pattern;
        dayprop.pattern = r.abbreviate;
        target[userindex].shiftdays[dayindex] = dayprop;
      });

      const yn = await this.$confirm("一括設定した内容を登録しますか。");
      if (yn === true) {
        // 更新OKの場合は、このままデータを登録
        await this.save();
      } else {
        // 更新を待たせた場合は、情報を表示する
        this.$info(
          "一括設定された内容は、登録が完了していません。\n登録を完了するには、「更新」を実行してください。"
        );
      }
    },
    getKey(day) {
      return Number(day.inputpatternindex[0]);
    },
    async calcUserCount() {
      console.log("calcUserCount");
      const usercount = [];
      this.shiftvalues.forEach(shiftvalue => {
        const nocountIndex = [];
        if (shiftvalue.length > 0) {
          const patterns = shiftvalue[0].patterns;
          patterns.forEach(p => {
            if (!p.masterExists) nocountIndex.push(p.index);
          });
        }

        const daymap = new Map();
        shiftvalue.forEach(v => {
          v.shiftdays.forEach(day => {
            let count = 0;
            if (daymap.has(day.day)) count = Number(daymap.get(day.day));
            if (!nocountIndex.includes(Number(day.inputpatternindex[0]))) {
              if (daymap.has(day.day)) count = Number(daymap.get(day.day));
              count += 1;
            }
            daymap.set(day.day, count);
          });
        });
        usercount.push(daymap);
      });
      console.log("usercount", usercount);
      this.usercounts = usercount;
    },
    async clickShift() {
      const result = await this.$confirm(
        "変更を破棄してシフトパターン画面に遷移します。\nよろしいですか？"
      );

      if (result) this.$router.push("list/shift-pattern");
    },
    async click(pathparam) {
      console.log("click", pathparam);
      const date = new Date(this.date);
      let result = true;
      if (this.shiftvalues.length > 0) {
        const message =
          pathparam === "query"
            ? "データを取得しますか？\n(未保存のデータは破棄されます。)"
            : pathparam === "copy"
            ? "前月データをコピーしますか？\n(未保存のデータは破棄されます。)\n(表示された内容は、「更新」を押すまで登録されません。)\n(有給等は、反映されません。)"
            : "現状届からシフトを展開しますか？\n(未保存のデータは破棄されます。)\n(表示された内容は、「更新」を押すまで登録されません。)";
        const title =
          pathparam === "query"
            ? "データ取得"
            : pathparam === "copy"
            ? "前月データコピー"
            : "現状届から展開";
        result = await this.$confirm(message, title);
      }

      if (result) {
        this.$loading();
        try {
          switch (this.viewType) {
            case ViewTypes[0].value:
              this.calendar = await this.getShiftMonth(
                date.getFullYear(),
                date.getMonth() + 1,
                pathparam
              );
              break;
            default:
              break;
          }
          this.tableTitle = this.format;
        } catch (e) {
          this.$error(e.message);
        } finally {
          this.$unloading();
        }
      }
    },
    async getShiftMonth(year, month, pathparam) {
      console.log("getShiftMonth", year, month, pathparam);
      // 選択した所属を取得
      console.log("selected", this.selected);
      this.affiliations = [];
      this.shiftvalues = [];
      this.shiftEvents = [];
      const param = {
        affiliations: this.selected,
        targetmonth: year + "-" + ("00" + month).slice(-2),
        searchtype: "2"
      };
      const encode = encodeURI(JSON.stringify(param));
      console.log("encode", encode);
      // API送信
      const result = await this.$get(
        this.Paths.shift,
        pathparam + "=" + encode
      );

      this.watchShiftValues = false;

      console.log("api_result", result);
      if (result.length == 0) {
        await this.$warning("該当するデータがありません。", "シフトデータ取得");
      } else {
        this.watchShiftValues = true;
        return await this.setShiftCalendar(result);
      }
    },
    async setShiftCalendar(apidata) {
      console.log("setShiftCalendar", apidata);

      let index = 0;
      this.delmode = true;
      apidata.forEach(s => {
        /*if (s.affiliation.busyo === "1") {
          this.delmode = true;
        }*/

        this.affiliations.push({
          id: s.affiliation.id,
          name: s.affiliation.name,
          index: index
        });

        const shiftValueList = [];

        s.details.forEach(c => {
          shiftValueList.push({
            usercode: c.code,
            username: c.name,
            shiftdays: c.calendars,
            patterns: s.patterns
          });
        });

        this.shiftvalues.push(shiftValueList);
        this.shiftEvents.push(s.shiftevents);
        console.log("shiftEvents", this.shiftEvents);

        index += 1;
      });
      const calendar = apidata[0].calendars;
      console.log("affiliations", this.affiliations);
      console.log("shiftvalues", this.shiftvalues);

      return calendar;
    },
    async save() {
      this.$loading();
      try {
        console.log("save", this.shiftvalues);
        this.upsertParam = [];
        // 画面入力値を参照して、登録更新対象を決定
        this.shiftvalues.forEach(shift => {
          shift.forEach(usershift => {
            usershift.shiftdays.forEach(v => {
              this.getUpsert(v, usershift.patterns);
            });
          });
        });
        console.log("save param", this.upsertParam);
        if (this.upsertParam.length == 0) {
          await this.$warning(
            "更新するデータがありません。",
            "シフトデータ更新"
          );
        } else {
          await this.$post(this.Paths.shiftUpsert, this.upsertParam);
          const date = new Date(this.date);
          this.calendar = await this.getShiftMonth(
            date.getFullYear(),
            date.getMonth() + 1,
            "query"
          );
          await this.$info("更新しました。");
        }
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    getUpsert(dayItem, patterns) {
      const savedPatterns = dayItem.savedpatternindex;
      const inputPatterns = dayItem.inputpatternindex;
      const resultI = inputPatterns.filter(i => savedPatterns.indexOf(i) == -1);
      const resultS = savedPatterns.filter(i => inputPatterns.indexOf(i) == -1);
      if (resultI.length == 0 && resultS.length == 0) return;
      console.log(dayItem, patterns);
      if (inputPatterns.length > 0 && inputPatterns[0] == 0) {
        // 勤務なし
        for (let i = 0, n = savedPatterns.length; i < n; ++i) {
          this.upsertParam.push({
            shiftid: dayItem.shiftIds[i],
            affiliationid: dayItem.affiliationid,
            datestr: dayItem.datestr,
            inputpatternid: dayItem.savedpatternid,
            savedpatternid: 0,
            userincode: dayItem.userincode
          });
        }
        return;
      }
      const target = [];

      inputPatterns.forEach(inp => {
        if (!savedPatterns.includes(inp)) {
          const patternId = patterns[Number(inp)].id
            ? patterns[Number(inp)].id
            : 0;
          if (patternId !== "0") {
            this.upsertParam.push({
              shiftid: "",
              affiliationid: dayItem.affiliationid,
              datestr: dayItem.datestr,
              inputpatternid: 0,
              savedpatternid: patternId,
              userincode: dayItem.userincode
            });
          }
        }
        target.push(inp);
      });

      const notExists = savedPatterns.filter(e => target.indexOf(e) == -1);
      notExists.forEach(n => {
        const index = savedPatterns.indexOf(n);
        const patternId = patterns[Number(n)].id ? patterns[Number(n)].id : 0;
        if (patternId !== "0") {
          this.upsertParam.push({
            shiftid: dayItem.shiftIds[index],
            affiliationid: dayItem.affiliationid,
            datestr: dayItem.datestr,
            inputpatternid: patternId,
            savedpatternid: 0,
            userincode: dayItem.userincode
          });
        }
      });
    },
    async getShozoku() {
      console.log("getShozoku");
      return await this.$get(this.Paths.shozokuFilter);
    },
    async getUsers() {
      console.log("getUsers");
      return await this.$get(this.Paths.shainFilter);
    },
    async addUser(affiliation) {
      console.log("addUser", affiliation.id);
      const param = {
        resulttype: "0",
        affiliationid: affiliation.id,
        affiliationname: affiliation.name,
        users: this.users
      };

      const selectusers = await this.$showDialog(ShiftUserAddDialogVue, {
        args: param
      });
      if (selectusers) {
        const date = new Date(this.date);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const param = {
          affiliationid: affiliation.id,
          users: selectusers,
          targetmonth: year + "-" + ("00" + month).slice(-2),
          searchtype: "2"
        };
        const encode = encodeURI(JSON.stringify(param));
        console.log("encode", encode);
        if (this.shiftvalues[affiliation.index].length == 0) {
          console.log("newuser-add");
          const apiresults = await this.$get(
            this.Paths.shift,
            "newuser-add=" + encode
          );

          apiresults.forEach(apiresult => {
            apiresult.details.forEach(c => {
              this.shiftvalues[affiliation.index].push({
                usercode: c.code,
                username: c.name,
                shiftdays: c.calendars,
                patterns: apiresult.patterns
              });
            });
          });
          console.log("shift-values", this.shiftvalues);
        } else {
          console.log("existuser-add");
          console.log("copytarget", this.shiftvalues[affiliation.index][0]);
          const copydata = this.shiftvalues[affiliation.index][0];
          const result = await this.$get(
            this.Paths.shift,
            "existuser-add=" + encode
          );
          result.forEach(c => {
            this.shiftvalues[affiliation.index].push({
              usercode: c.code,
              username: c.name,
              shiftdays: c.calendars,
              patterns: copydata.patterns
            });
          });
          console.log("shift-values", this.shiftvalues);
        }
      }
    },
    async deleteded() {
      console.log("deleteded");

      const confirm = await this.$confirm(
        "実績に紐づいていないシフトを削除しますか？\n " +
          "※実績が未登録の場合も削除されますので注意してください。",
        "不要なシフト削除"
      );
      if (!confirm) return;

      this.$loading();

      const date = new Date(this.date);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;

      try {
        const param = {
          affiliations: this.selected,
          targetmonth: year + "-" + ("00" + month).slice(-2),
          searchtype: "2"
        };
        const encode = encodeURI(JSON.stringify(param));
        console.log("encode", encode);
        const result = await this.$del(this.Paths.shiftded, "query=" + encode);

        const confirm = await this.$info(
          "削除が完了しました。",
          "不要なシフト削除"
        );
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async excel() {
      console.log("excel");

      const confirm = await this.$confirm(
        "シフト表をダウンロードしますか？",
        "EXCEL出力"
      );
      if (!confirm) return;
      this.$loading();
      const date = new Date(this.date);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      try {
        const param = {
          affiliations: this.selected,
          targetmonth: year + "-" + ("00" + month).slice(-2),
          searchtype: "2"
        };
        const encode = encodeURI(JSON.stringify(param));
        console.log("encode", encode);
        const result = await this.$downloadexcel(
          this.Paths.shiftExcel,
          "query=" + encode
        );
        const url = URL.createObjectURL(result);
        const link = document.createElement("a");
        link.href = url;
        link.download = "download-filename.zip";
        link.click();
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    }
  },
  async created() {
    console.log("created");

    this.$loading();
    try {
      this.offices = await this.getShozoku();
      this.users = await this.getUsers();
      this.date = this.$getAiwaServiceDate();
      console.log(this.date);

      console.log("query", this.$route.query);
      if ("filter" in this.$route.query) {
        const param = this.$route.query.filter;
        if (typeof param !== "undefined" && Object.keys(param).length) {
          this.selected = param.selected;
          this.date = param.date;
          const dt = new Date(this.date);
          this.calendar = await this.getShiftMonth(
            dt.getFullYear(),
            dt.getMonth() + 1,
            "query"
          );
          this.tableTitle = this.format;
        }
      }
    } catch (e) {
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  },
  async mounted() {
    console.log("mounted");
  }
};
</script>

<style></style>

<style lang="scss" scoped>
.office {
  height: 800px;
  table {
    height: calc(
      100% - 80px
    ); /*
  border-collapse: separate;
  border-spacing: 0; */
    border-collapse: collapse;
    display: block;
    overflow-x: scroll;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
  thead {
    position: sticky;
    top: 0;
    z-index: 3;
  }
  th {
    background-color: white !important;
  }
  tbody th {
    width: 180px;
    min-width: 180px;
  }
  tbody td {
    width: 100px;
    min-width: 100px;
  }
}
.bold-text {
  font-weight: bold;
}
.pulldown-z-index {
  z-index: 3;
}
.fixed {
  position: sticky;
  left: 0;
  color: #333;
  background-color: white;
  z-index: 2;
  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
  }
}
.private {
  table {
    // border-collapse: collapse;
    width: 100%;
  }
  // td {
  //   height: 80px;
  // }
}
.person {
  width: 50px;
}
.person::after {
  content: "ms";
}
.shift-event {
  font-size: 12px;
}

.warning-col {
  background-color: #ffcc66;
}

.worktime-span {
  float: right;
  margin-right: 15px;
}
</style>
